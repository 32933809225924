.second-image {
  grid-column: 1/3;
  grid-row: 2/3;
}

.home-mountain-overlay {
  grid-row: 1/2;
  grid-column: 1/2;
  z-index: 3;
}

.container-collage {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-auto-rows: 200px;
  grid-gap: 5px;
  grid-auto-flow: dense;
}

.container-collage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.horizontal-collage {
  grid-column: span 2;
}

.vertical-collage {
  grid-row: span 2;
}

.big-collage {
  grid-column: span 2;
  grid-row: span 2;
}

.card-lift {
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px;
  transition: all 0.3s ease-in-out;
  border-radius: 15px;
}

.card-lift:hover {
  box-shadow: rgba(0, 0, 0, 0.22) 0px 19px 43px;
  transform: translate3d(0px, -2px, 0px);
  border-radius: 15px;
}

.marilyn {
  max-width: 300px;
}
