.greenText {
  color: #001c17;
}

.developerTag {
  color: #ffffff;
}

.developerTag:hover {
  color: #F75A0E;
}

.verticalBreak {
  border-left: 1px solid #ffffff;
  height: 10px;
  margin-right: 0.5em;
  margin-left: 0.5em;
}

.footerLink {
  color: #ffffff;
  text-decoration: none;
}

.footerLink:hover {
  color: #F75A0E;
}

.developerLink {
  color: #EAEAEA;
  text-decoration: none;
}

.developerLink:hover {
  color: #F75A0E;
}