.tripDetailList {
  list-style-type: none;
  line-height: 1.5;
}

.tripDetailsListContainer {
  margin-top: 1em;
  text-align: center;
  color: #ffffffAA;
}
