.scroll-to-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1;
  background-color: #F75A0E;
  color: white;
  border: none;
  border-radius: 20%;
  cursor: pointer;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  height: 40px;
  width: 40px;
}

.scroll-to-top-button:hover {
  background-color: #506A63;
}
