.pageNav {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 0.5em;
  font-size: 18px;
}

.packing-btn {
  height: 50px;
}

.day-btn {
  height: 50px;
}

.faq-btn {
  height: 50px;
}

@media only screen and (min-width: 800px) {
  .pageNav {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0.5em;
  }
}
